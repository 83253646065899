import { useLocation } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '../shared/hooks';

export const Seo = ({ title = null, description = null, children }) => {
    const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername } = useSiteMetadata();
    const { pathname } = useLocation();

    const seo = {
        title: !!title ? title + ' - AIDA' : defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${pathname || ``}`,
        twitterUsername
    };

    return <Helmet>
        <title>{seo.title}</title>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:url" content={seo.url} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
        <meta name="twitter:creator" content={seo.twitterUsername} />
        <meta name="google-site-verification" content="03vteB0t6D9mHLQL6Y9jAyHVWU2q8deyHO-Mc5mD0iQ" />
        <link rel="canonical" href={seo.url} />
        {/* tclab */}
        <meta name="google-site-verification" content="aTXck0VVQuhcYxfhuqYyO-8dYnu2JPzj_bOl6WwayB0" />
        {children}
    </Helmet>;
};
